import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { firebase } from "../Firebase";
import { v4 as uuidv4 } from "uuid";

const ComplainBox = () => {
  const [complaint, setComplaint] = useState("");
  const db = firebase.firestore();

  const handleComplaintChange = (e) => {
    setComplaint(e.target.value);
  };

  const handleSubmit = async () => {
    const complaintsCollection = db.collection("complain");

    const data = { complainMessage: complaint };
    if (data.complainMessage === "") {
      window.alert("Field is empty!");
      return;
    }

    if (data.complainMessage.length > 255) {
      window.alert("Length should be less than 255 characters!");
      return;
    }
    const complainId = uuidv4();
    try {
      await complaintsCollection.add({
        complainId: complainId,
        complainMessage: data.complainMessage,
      });
      window.alert("Complaint sent successfully");
      setComplaint("");
    } catch (error) {
      console.error("Error:", error);
      window.alert("Failed to send complaint");
    }
  };

  return (
    <div id="complainBoxMain">
      <div id="complainBoxInput">
        <h1>Complain</h1>
        <textarea
          placeholder="Enter Complain Here..."
          rows="4"
          cols="50"
          id="complainInput"
          value={complaint}
          onChange={handleComplaintChange}
        ></textarea>
        <button onClick={handleSubmit}>
          <SendIcon sx={{ fontSize: "30px", color: "white" }} />
        </button>
      </div>
    </div>
  );
};

export default ComplainBox;
