import React, { useEffect, useState } from "react";
import MoviePagination from "./MoviePagination";
import MovieList from "./MovieList";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { firebase } from "../Firebase";

const Content = ({ searchQuery, selectedCategory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const moviesPerPage = 20;
  const [movieData, setMovieData] = useState([]);
  const [loading, setLoading] = useState(true);
  const db = firebase.firestore();

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const getMoviesData = async () => {
    setLoading(true);
    try {
      const moviesCollection = db.collection("movies");
      const moviesSnapshot = await moviesCollection.get();
      const moviesList = moviesSnapshot.docs.map((doc) => doc.data());
      setMovieData(moviesList);
      setLoading(false);
    } catch (error) {
      alert("Error fetching movies from Firestore:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMoviesData();
  }, []);

  const getMoviesByCategory = async () => {
    setLoading(true);
    try {
      const moviesCollection = db.collection("movies");
      const querySnapshot = await moviesCollection.get();

      const allMoviesList = querySnapshot.docs.map((doc) => doc.data());
      let categoryMovies;
      if (selectedCategory !== "All") {
        categoryMovies = allMoviesList.filter((movie) =>
          movie.movieCategory
            .toLowerCase()
            .includes(selectedCategory.toLowerCase())
        );
      } else {
        categoryMovies = allMoviesList;
      }
      setMovieData(categoryMovies);
      setLoading(false);
    } catch (error) {
      alert("Error fetching movies by category from Firestore:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMoviesByCategory();
  }, [selectedCategory]);

  const totalPages = Math.ceil(movieData.length / moviesPerPage);

  const filterMovies = () => {
    if (!searchQuery) {
      return movieData;
    }
    return movieData.filter((movie) =>
      movie.movieTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredMovieData = filterMovies();

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <div id="contentMain">
        {loading ? (
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginTop: "50px",
            }}
          >
            {/* Use the ClipLoader from react-spinners */}
            <ClipLoader
              color="#FEA641"
              loading={loading}
              css={override}
              size={50}
            />
          </div>
        ) : filteredMovieData.length === 0 ? (
          <p
            style={{
              fontSize: "20px",
              color: "red",
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginTop: "50px",
            }}
          >
            No such movie found!
          </p>
        ) : (
          <MovieList
            currentPage={currentPage}
            moviesPerPage={moviesPerPage}
            movieData={filteredMovieData}
          />
        )}
      </div>
      <MoviePagination totalPages={totalPages} onPageChange={onPageChange} />
    </>
  );
};

export default Content;
