import React from "react";
import { Link } from "react-router-dom";

const MovieList = ({ currentPage, moviesPerPage, movieData }) => {
  const sortedMovieData = movieData
    .slice()
    .sort((a, b) => b.movieReleaseYear - a.movieReleaseYear);

  const startIndex = (currentPage - 1) * moviesPerPage;
  const endIndex = startIndex + moviesPerPage;

  return (
    <>
      {sortedMovieData.slice(startIndex, endIndex).map((movie, index) => (
        <div className="movieDiv" key={index}>
          <Link
            to={`/movie/${movie.moviesId}/page/${currentPage}`}
            style={{ textDecoration: "none" }}
          >
            <img src={movie.moviesImageUrl} alt={movie.movieTitle} />
            <p>{`${movie.movieTitle} (${movie.movieReleaseYear}) - ${movie.movielanguagesAvailable} (${movie.movie480QualitySize} / ${movie.movie720QualitySize} / ${movie.movie1080QualitySize})`}</p>
          </Link>
        </div>
      ))}
    </>
  );
};

export default MovieList;
