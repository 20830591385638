import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useRef } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ onSearch, onSelectCategory }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  const handleSearch = () => {
    onSearch(searchQuery);
    navigate("/");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // Call the search function on "Enter" key press
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
    if (inputValue === "") {
      // Automatically trigger the search when the input is cleared
      onSearch("");
      navigate("/");
    }
  };

  var items = [
    "All",
    "Action",
    "Adult",
    "Adventure",
    "Anime",
    "Comedy",
    "Crime",
    "Documentary",
    "Fantasy",
    "Heist",
    "Horror",
    "JungleAdventure",
    "Mystery",
    "Romance",
    "SciFi",
    "SpaceAdventure",
    "Sports",
    "SuperHero",
    "Survival",
    "Thriller",
    "TimeTravel",
    "War",
    "Zombie",
  ];
  var stepSize = 75;
  const [currentPosition, setCurrentPosition] = useState(0);

  const handleCategoryChange = (category) => {
    onSelectCategory(category);
    navigate("/");
  };

  const handleRightClick = () => {
    const maxPosition = -((items.length - 1) * stepSize);
    if (currentPosition > maxPosition) {
      setCurrentPosition(currentPosition - stepSize);
    }
  };

  const handleLeftClick = () => {
    if (currentPosition < 0) {
      setCurrentPosition(currentPosition + stepSize);
    }
  };

  return (
    <>
      <div id="navbar">
        <div id="leftNav">
          <Link to={"/"} style={{ color: "#FEA641", textDecoration: "none" }}>
            <h1>Movie Venture</h1>
          </Link>
        </div>
        <div id="bottomNav">
          <input
            id="searchBar"
            placeholder="Search here..."
            onKeyPress={handleKeyPress}
            ref={searchInputRef}
            value={searchQuery}
            onChange={handleInputChange}
          />
          <button id="searchBtn" onClick={handleSearch}>
            <SearchIcon
              sx={{
                backgroundColor: "transparent",
                color: "white",
                fontSize: "28px",
                fontWeight: "bold",
              }}
            />
          </button>
        </div>
        <div id="rightNav">
          <button className="rightNavBtn">Bollywood</button>
          <button className="rightNavBtn">Live</button>
        </div>
      </div>
      {/* ====================New Navbar======================================================== */}
      <div id="newNavbar">
        <div id="newLeftNav">
          <Link to={"/"} style={{ color: "#FEA641", textDecoration: "none" }}>
            <img src={require("../Assets/logo.jpg")} alt="Logo" />
          </Link>
        </div>

        <div id="newBottomNav">
          <input
            id="newSearchBar"
            placeholder="Search here..."
            onKeyPress={handleKeyPress}
            ref={searchInputRef}
            value={searchQuery}
            onChange={handleInputChange}
          />
          <button id="newSearchBtn" onClick={handleSearch}>
            <SearchIcon
              sx={{
                backgroundColor: "transparent",
                color: "white",
                fontSize: "28px",
                fontWeight: "bold",
              }}
            />
          </button>
        </div>

        <div id="newRightNav">
          <button className="newRightNavBtn">Bollywood</button>
          <button className="newRightNavBtn">Live</button>
        </div>
      </div>

      {/* ========================================================================= */}
      <div id="optionsMain">
        <button onClick={handleLeftClick}>
          <ChevronLeftIcon
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "100%",
              height: "100%",
              display: "flex",
              alignContent: "center",
            }}
          />
        </button>
        <div
          id="options"
          style={{ transform: `translateX(${currentPosition}px)` }}
        >
          {items.map((item, index) => (
            <p key={index} onClick={() => handleCategoryChange(item)}>
              {item}
            </p>
          ))}
        </div>
        <button onClick={handleRightClick}>
          <ChevronRightIcon
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "100%",
              height: "100%",
              display: "flex",
              alignContent: "center",
            }}
          />
        </button>
      </div>

      <div id="newOptionsMain">
        <button onClick={handleLeftClick}>
          <ChevronLeftIcon
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "100%",
              height: "100%",
              display: "flex",
              alignContent: "center",
            }}
          />
        </button>
        <div
          id="newOptions"
          style={{ transform: `translateX(${currentPosition}px)` }}
        >
          {items.map((it, i) => (
            <p key={i} onClick={() => handleCategoryChange(it)}>
              {it}
            </p>
          ))}
        </div>
        <button onClick={handleRightClick}>
          <ChevronRightIcon
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "100%",
              height: "100%",
              display: "flex",
              alignContent: "center",
            }}
          />
        </button>
      </div>
    </>
  );
};

export default Navbar;
