import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firebase } from "../Firebase";

const Movie = () => {
  const { moviesId } = useParams(); // we can use currentPage in the brackets {}
  const [data, setData] = useState([]);
  const [recommendedMovies, setRecommendedMovies] = useState([]);
  const db = firebase.firestore();

  const getMovieData = async () => {
    try {
      const movieDocRef = db.collection("movies");
      const selectedMovie = movieDocRef.where("moviesId", "==", moviesId);
      const movieDocSnap = await selectedMovie.get();

      if (movieDocSnap.empty) {
        console.error(`No movie found with ID: ${moviesId}`);
      } else {
        const movieData = movieDocSnap.docs[0].data();
        setData(movieData);
      }
    } catch (error) {
      console.error("Error fetching movie data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMovieData();
  }, [moviesId]);

  useEffect(() => {
    const fetchRecommendedMovies = async () => {
      const moviesCollection = db.collection("movies");
      const recommendedMoviesQuery = moviesCollection.where(
        "moviesId",
        "!=",
        moviesId
      );
      const recommendedMoviesSnapshot = await recommendedMoviesQuery.get();

      const recommendedMoviesList = recommendedMoviesSnapshot.docs.map(
        (doc) => ({
          ...doc.data(),
          id: doc.id,
        })
      );

      setRecommendedMovies(recommendedMoviesList);
    };

    fetchRecommendedMovies();
  }, [data.moviesId]);

  const handleRecommendedMovieClick = (movie) => {
    setData(movie);
  };

  const handleDownload = (quality) => {
    const movieDownload = data[`movie${quality}pVideoLink`];
    if (movieDownload) {
      const mainDownloadLink = `https://drive.google.com/uc?id=${movieDownload}`;
      window.open(mainDownloadLink, "_blank");
    } else {
      // Handle the case when the download link is not available
      window.alert(`Sorry, download link not available`);
    }
  };

  return (
    <>
      <div id="movieInfoMain">
        <div id="movieInfoLeft">
          <h2>{`Download ${data.movieTitle} (${data.movieReleaseYear}) ${data.movielanguagesAvailable} ${data.movie480QualitySize} || ${data.movie720QualitySize} || ${data.movie1080QualitySize}`}</h2>
          <div id="selectedMovieDetails">
            <div id="selectedMovieImage">
              <img src={data.moviesImageUrl} alt={data.movieTitle} />
            </div>
            <div id="selectedMovieInfo">
              <p>
                <b style={{ color: "#FEA641" }}> Title: </b> {data.movieTitle}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Duration: </b>
                {data.movieDuration}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Release Year: </b>
                {data.movieReleaseYear}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Quality Available: </b> 480p,
                720p and 1080p
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Langauge Available: </b>
                {data.movielanguagesAvailable}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Subtitle: </b>
                {data.movieSubtitle}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Rating: </b> {data.movieRating}
                / 10
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Category: </b>
                {data.movieCategory}
              </p>
              <p>
                <b style={{ color: "#FEA641" }}> Summary: </b>
                {data.movieDescription}
              </p>
            </div>
          </div>
          <h2 id="screenshotsHeading">Screenshots: </h2>
          <div id="screenshotsImagesDiv">
            <div className="screenshotsImages">
              <img src={data.imageUrl1} alt={data.movieTitle} />
            </div>
            <div className="screenshotsImages">
              <img src={data.imageUrl2} alt={data.movieTitle} />
            </div>
            <div className="screenshotsImages">
              <img src={data.imageUrl3} alt={data.movieTitle} />
            </div>
            <div className="screenshotsImages">
              <img src={data.imageUrl4} alt={data.movieTitle} />
            </div>
            <div className="screenshotsImages">
              <img src={data.imageUrl5} alt={data.movieTitle} />
            </div>
          </div>
          <h2 id="downloadHeading">Download Links: </h2>
          <div id="downloadLinks">
            <div>
              <p>{`Download ${data.movieTitle} (${data.movieReleaseYear}) (${data.movielanguagesAvailable}) ${data.movie480QualitySize}`}</p>
              <button onClick={() => handleDownload(480)}>Download</button>
            </div>
            <div>
              <p>{`Download ${data.movieTitle} (${data.movieReleaseYear}) (${data.movielanguagesAvailable}) ${data.movie720QualitySize}`}</p>
              <button onClick={() => handleDownload(720)}>Download</button>
            </div>
            <div>
              <p>{`Download ${data.movieTitle} (${data.movieReleaseYear}) (${data.movielanguagesAvailable}) ${data.movie1080QualitySize}`}</p>
              <button onClick={() => handleDownload(1080)}>Download</button>
            </div>
          </div>
        </div>
        <div id="movieInfoRight">
          <div id="recommededMovieTitle">
            <p>Recommeded Movies</p>
          </div>
          <div id="movieRecommededAppend">
            {recommendedMovies.map((recommendedMovie) => (
              <div
                key={recommendedMovie.moviesId}
                className="movieRecommeded"
                onClick={() => handleRecommendedMovieClick(recommendedMovie)}
              >
                <p>{`${recommendedMovie.movieTitle} (${recommendedMovie.movieReleaseYear}) (${recommendedMovie.movielanguagesAvailable}) ${recommendedMovie.movie480QualitySize} || ${recommendedMovie.movie720QualitySize} || ${recommendedMovie.movie1080QualitySize}`}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Movie;
