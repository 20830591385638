import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAkSOwQiCecQQ-IbsWI_Y-8U6OFA9m__9I",
  authDomain: "movieventureadminpanel.firebaseapp.com",
  projectId: "movieventureadminpanel",
  storageBucket: "movieventureadminpanel.appspot.com",
  messagingSenderId: "1092793702833",
  appId: "1:1092793702833:web:2e0739ca2d0e5801ae0590",
  databaseURL:
    "https://movieventureadminpanel-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
